import React, { useState } from 'react'
import { RichText } from 'prismic-reactjs'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { cover } from 'polished'

import {
  Box,
  Button,
  DisplayText,
  FlexCol,
  FlexGrid,
  GivingCard,
  Text,
  H3,
  H6,
  Highlight,
  SEO,
} from 'src/components'

import serializer from 'src/utils/serializer'
import linkResolver from 'src/utils/linkResolver'

function CampaignPage({ data, theme }) {
  let dataParsed
  if (data.campaign.dataString) {
    dataParsed = JSON.parse(data.campaign.dataString)
  }

  const [amount, setAmount] = useState(dataParsed.min_donation_amount)

  return (
    <>
      <SEO
        title={
          (dataParsed.campaign_title
            ? dataParsed.campaign_title[0].text
            : 'Campaign') + ' | Ssubi Foundation - Hope is what we have'
        }
      />
      <Box px={[3, null, null, 4]} py={4} bg="bg.default">
        <Box maxWidth={1280} mx="auto">
          <FlexCol alignItems="center">
            {dataParsed.campaign_title && (
              <DisplayText
                mb="0.5em"
                textAlign="center"
                style={{ transform: 'rotate(-7.5deg)' }}
              >
                <Highlight variant={1}>
                  {dataParsed.campaign_title[0].text}
                </Highlight>
              </DisplayText>
            )}
          </FlexCol>
        </Box>
        <Box width={1} maxWidth={1280} mx="auto" mt={4} bg="bg.wash">
          <FlexGrid style={{ minHeight: '500px' }}>
            <Box
              position="relative"
              width={[1, 1 / 2]}
              height={['300px', 'auto']}
            >
              <Img
                fluid={data.campaign.data.campaign_image.fluid}
                style={{
                  ...cover(),
                  width: '100%',
                  height: '100%',
                  userSelect: 'none',
                }}
              />
            </Box>
            <FlexCol width={[1, 1 / 2]}>
              <FlexCol flex={1} p={[3, null, 4]}>
                {dataParsed.campaign_title && (
                  <H3
                    children={dataParsed.campaign_title[0].text}
                    pb={2}
                    textAlign="center"
                  />
                )}
                {dataParsed.campaign_description && (
                  <Box maxWidth={512} mx="auto" pb={3} textAlign="center">
                    {RichText.render(
                      dataParsed.campaign_description,
                      linkResolver,
                      serializer
                    )}
                  </Box>
                )}
                <Box mt="auto" textAlign="center">
                  {dataParsed.campaign_goal === 'Donations' && (
                    <div>
                      <H6
                        children={`Enter an amount to give`}
                        mb={2}
                        textAlign="center"
                      />
                      <div
                        css={`
                          position: relative;
                        `}
                      >
                        <div
                          css={`
                            position: absolute;
                            top: 50%;
                            left: 8px;
                            transform: translateY(-50%);
                            color: ${props => props.theme.colors.text.default};
                            pointer-events: none;
                          `}
                        >
                          <svg
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            css={`
                              vertical-align: top;
                            `}
                          >
                            <path d="M12 1v22M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6" />
                          </svg>
                        </div>
                        <input
                          placeholder={`10,000`}
                          type="number"
                          css={`
                            width: 100%;
                            padding: 20px 140px 20px 32px;
                            border: 0;
                            border-radius: 4px;
                            font-family: ${props => props.theme.fonts.sans};
                            font-size: 16px;
                            background-color: #fff;
                            &::placeholder {
                              color: rgba(0, 0, 0, 0.5);
                              opacity: 1;
                            }
                            &:focus {
                              outline: 0;
                              box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.25);
                            }
                            &::-webkit-inner-spin-button,
                            &::-webkit-outer-spin-button {
                              -webkit-appearance: none;
                              margin: 0;
                            }
                          `}
                          value={amount}
                          onChange={event => {
                            setAmount(event.target.value)
                          }}
                        />
                        <div
                          css={`
                            position: absolute;
                            top: 50%;
                            right: 8px;
                            transform: translateY(-50%);
                          `}
                        >
                          <Button
                            children={`Donate`}
                            appearance="yellow"
                            as={'a'}
                            href={
                              'https://www.canadahelps.org/en/dn/40069?amount=' +
                              amount
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            disabled={
                              dataParsed.campaign_goal === 'Donations'
                                ? amount < dataParsed.min_donation_amount
                                  ? true
                                  : false
                                : false
                            }
                          />
                        </div>
                      </div>
                      {amount < dataParsed.min_donation_amount &&
                        amount !== '' && (
                          <Text
                            children={
                              `Monthly donation must be greater than $` +
                              dataParsed.min_donation_amount
                            }
                            fontSize={0}
                            color="text.alt"
                            mt={1}
                          />
                        )}
                    </div>
                  )}
                  {dataParsed.campaign_goal !== 'Donations' && (
                    <Button
                      as="a"
                      href={
                        'mailto:info@ssubifoundation.org?subject=' +
                        dataParsed.campaign_title[0].text
                      }
                      children={`Contact us`}
                      appearance="yellow"
                    />
                  )}
                </Box>
              </FlexCol>
            </FlexCol>
          </FlexGrid>
        </Box>
        {(data.campaigns || data.products) && (
          <Box width={1} maxWidth={1280} mx="auto" mt={4}>
            <H3 children={`Other ways to give`} mb={4} textAlign="center" />
            <FlexGrid gutterY={[3]}>
              {data.campaigns && (
                <>
                  {data.campaigns.edges.map((campaign, index) => {
                    if (campaign.node.uid !== data.campaign.uid) {
                      return (
                        <Box mb={2} width="100%">
                          <GivingCard
                            color={campaign.node.data.campaign_color.toLowerCase()}
                            image={
                              campaign.node.data.campaign_image
                                ? campaign.node.data.campaign_image
                                  ? campaign.node.data.campaign_image.fluid
                                  : undefined
                                : undefined
                            }
                            title={
                              campaign.node.data.campaign_title
                                ? campaign.node.data.campaign_title.text
                                : ''
                            }
                            description={
                              campaign.node.data.campaign_description
                                ? campaign.node.data.campaign_description.text
                                : ''
                            }
                            link={'/campaign/' + campaign.node.uid}
                          />
                        </Box>
                      )
                    } else {
                      return null
                    }
                  })}
                </>
              )}
              {data.products && (
                <>
                  {data.products.edges.map(product => {
                    return (
                      <Box mb={2} width="100%">
                        <GivingCard
                          color={product.node.data.product_color.toLowerCase()}
                          image={
                            product.node.data.product_image
                              ? product.node.data.product_image
                                ? product.node.data.product_image.fluid
                                : undefined
                              : undefined
                          }
                          title={
                            product.node.data.product_name
                              ? product.node.data.product_name.text
                              : ''
                          }
                          description={
                            product.node.data.product_description
                              ? product.node.data.product_description.text
                              : ''
                          }
                          link={'/product/' + product.node.uid}
                        />
                      </Box>
                    )
                  })}
                </>
              )}
            </FlexGrid>
          </Box>
        )}
      </Box>
    </>
  )
}

export default CampaignPage

export const query = graphql`
  query($uid: String!) {
    campaign: prismicCampaign(uid: { eq: $uid }) {
      uid
      id
      dataString
      data {
        campaign_image {
          fluid(maxWidth: 1200) {
            ...GatsbyPrismicImageFluid
          }
        }
      }
    }
    campaigns: allPrismicCampaign {
      edges {
        node {
          uid
          id
          data {
            campaign_image {
              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            campaign_title {
              text
            }
            campaign_description {
              text
            }
            campaign_color
          }
        }
      }
    }
    products: allPrismicProduct {
      edges {
        node {
          uid
          id
          data {
            product_image {
              fluid(maxWidth: 1200) {
                ...GatsbyPrismicImageFluid
              }
            }
            product_color
            product_name {
              text
            }
            product_description {
              text
            }
          }
        }
      }
    }
  }
`
